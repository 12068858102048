import React, { useEffect, useState } from "react"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import UseSiteMetadata from "../components/UseSiteMetadata"
import StaticWeb from "../svg/static-website-svg"
import Server from "../svg/server-website-svg"

const IndexEN = ({ pageContext, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const  fullUrl = `${siteUrl}${location.pathname}`
  const [imgWidth, setImgWidth] = useState("")
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo:"https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": `https://netbistrot.com${location.pathname}`,
        url: `https://netbistrotcom${location.pathname}`,
        name: "NetBistrot",
        inLanguage: "en-US",

      },
    ],
  }

  useEffect(() => {
    setImgWidth(document.getElementById("svg-wrapper").offsetWidth - 32 + "px")
  }, [])
  return (
    <>
    {console.log({imgWidth})}
      <Metatags
        title="NetBistrot - Digital agency | Static websites"
        description="Static websites working description"
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="websites, pwa, social media, graphic design"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <NavBar />
      <div className="h-10" />
      <div className="container mx-auto">
        <div className=" mx-2 md:mx-0">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-8 text-center">
              <p className=" text-3xl">Static websites</p>
            </div>
          </div>
          <div className="h-10" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-8 text-center">
              <div className="bg-white p-4 rounded-3xl" id="svg-wrapper">
                <div className="  w-fit mx-auto">
                  <StaticWeb width={imgWidth} />
                </div>
              </div>
              <div className="h-12" />
              <div className="text-left">
                <p>
                  We deploy our systems through a CDN (Content Delivery Network)
                  that is an isolated environment. It can receive data from the
                  servers but do not send any data back to the servers. This
                  improves the security.
                </p>
                <p>&nbsp;</p>
                <p>
                  Actually the server is not working all the time, only when
                  some update is required. Then the server starts, process the
                  updates, generates a new batch of optimized files (html, css
                  and javascript), send this files to the CDN and shutdown. This
                  improves energy efficiency and decreases the CO2 footprint. It
                  also lowers costs.
                </p>
                <p>&nbsp;</p>
                <p>
                  Updates in the website may be performed through programming or
                  by a change in the database. To change the database there is a
                  CMS (Content Management System) that performs CRUD (create,
                  update and delete) operations.
                </p>
                <p>&nbsp;</p>
                <p>
                  A CDN can answer hundreds of thousands of requests per second.
                  So, even in high utilization peaks, nobody gets out. This is
                  scalability.
                </p>
                <p>&nbsp;</p>
                <p>
                  A CDN with optimized files answers the requests much faster
                  than a server. This improves the user experience and the SEO
                  (Search Engine Optimization) rank.
                </p>
                <p>&nbsp;</p>
                <p>Why is that different?</p>
              </div>
            </div>
          </div>
          <div className="h-20" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-8 text-center">
              <p className=" text-3xl">Server based websites</p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-8 text-center">
              <div className="h-12" />
              <div className="text-left">
                <p>
                  These are the websites powered by systems like wordpress or Drupal CMS.
                </p>
                <p>&nbsp;</p>
                <p>We do not work with this systems anymore.</p>
              </div>
            </div>
          </div>
          <div className="h-10" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-8 text-center">
              <div className="bg-white p-4 rounded-3xl" id="svg-wrapper">
                <div className="  w-fit mx-auto">
                  <Server width={imgWidth} />
                </div>
              </div>
              <div className="h-12" />
              <div className="text-left">
                <p>
                  Server based websites needs processing power for every
                  operation.
                </p>
                <p>&nbsp;</p>
                <p>
                  As the CMS (Content Management System) is integrated in the
                  same server the system is more susceptible to attacks.
                </p>
                <p>&nbsp;</p>
                <p>
                  Updates are managed by the CMS, that performs CRUD (create,
                  update and delete) operations, and became immediately
                  available in the database.
                </p>
                <p>&nbsp;</p>
                <p>
                  For every request a user performs the system needs to access
                  the information in database and prepare, on the fly, the file
                  set (html, css and javascript) to send to user. This takes
                  more time, more processing power and more electrical energy.
                  In terms of money is more expensive.
                </p>
                <p>&nbsp;</p>
                <p>
                  Another drawback of the server based websites is that there is
                  a processing capacity limit. If too many users try to access
                  the website at the same time the time to response slows down
                  and the system may even become unresponsive.
                </p>
                <p>&nbsp;</p>
                <p>
                  There are a few strategies to overcome theses issues like to
                  storage the processed files in a cachet area or automatically
                  launch more servers once the processing power of the first one
                  surpass a given limit. But it will never be as fast, as
                  economic or as safe like a static website.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <div className="h-10" />
      <Footer lang="en" />
    </>
  )
}

export default IndexEN
